.extraBottomPadding {
  padding-bottom: 2.5rem;
}
.header {
  max-width: 911px;
  margin: auto;
  text-align: center;
  margin-bottom: 4rem;
}
.cardContainer {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: flex-start; */
}

.singleCard {
  width: 32%;
  border-radius: 16px;
  background: var(--fill-selection, #f6f8fe);
  display: flex;
  flex-direction: column;
}

.imageContainer {
  border-radius: 16px;
  display: flex;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  align-items: center;
  justify-content: center;
}
.cardImg {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 100%;
}
.contentContainer {
  padding: 16px;
}
.cardTitle {
  color: #191653;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.0125rem;
}
.cardSubTitle {
  margin-top: 8px;
  color: #191653;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
}

@media screen and (max-width: 1111px) {
  .singleCard {
    width: 30%;
  }
}

@media screen and (max-width: 900px) {
  .singleCard {
    width: 48%;
  }
}

@media screen and (max-width: 600px) {
  .singleCard {
    width: 99%;
  }
  .cardTitle {
    font-size: 16px;
  }
  .cardSubTitle {
    font-size: 14px;
  }
}
